<script>
import HelpService from "@/services/HelpService";

export default {
    name: "VToc",
    props: {
        helpSlug: {
            default: "",
            type: String,
        },
        helpCollection: {
            default: "",
            type: String,
        },
    },
    data() {
        return {
            loading: false,
            docLoading: false,
            toc: [],
            doc: "",
            tocAdmin: [], 
            docAdmin: ""
        };
    },
    watch: {
        helpSlug: function (nVal) {
            if (nVal != "") {
                this.fetchDocument(this.helpCollection, nVal);
            }
        },
    },
    async created() {
        await this.fetchToc();        

            console.log(this.toc);
        if (this.helpSlug != "") {
            this.fetchDocument(this.helpCollection, this.helpSlug);
        } else {
            this.fetchDocument('users', this.toc[0]['query']);
        }
    },
    methods: {
        async fetchToc() {
            try {
                this.loading = true;
                const r = await HelpService.GET_toc();
                this.toc = r.data.data['users'];
                
                if (r.data.data['admin'].length) {
                    this.tocAdmin = r.data.data['admin'];
                }
            } catch (e) {
                console.log("fetchToc exception", e);
            }

            this.loading = false;
        },
        async fetchDocument(collection, doc) {
            try {
                this.docLoading = true;
                const r = await HelpService.GET_document(collection, doc);
                this.doc = r.data.data;
            } catch (e) {
                console.log("Markdown");
            }

            this.docLoading = false;
        },
    },
};
</script>

<template>
    <div>
        <base-header class="pb-1" type="">
            <bread-bag></bread-bag>
        </base-header>

        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                
                    <card>
                        <div class="mg-toc">
                            <div class="mg-toc__navi">
                                <ul class="nav flex-column">
                                    <li class=""><b>{{$t('doc.user_docs')}}</b></li>
                                    <li
                                        v-for="(doc, index) in toc"
                                        :key="index"
                                        class="nav-item"
                                    >
                                        <router-link
                                            class="nav-link"
                                            :to="{
                                                name: 'Help',
                                                query: { page: doc.query, collection: 'users' },
                                            }"
                                            >{{ doc.title }}</router-link
                                        >
                                    </li>
                                    <template v-if="tocAdmin.length">
                                        <li class="mt-3"><b>{{$t('doc.admin_docs')}}</b></li>
                                        <li
                                        v-for="(doc, index) in tocAdmin"
                                        :key="`admin_${index}`"
                                        class="nav-item"
                                    >
                                        <router-link
                                            class="nav-link"
                                            :to="{
                                                name: 'Help',
                                                query: { page: doc.query, collection: 'admin' },
                                            }"
                                            >{{ doc.title }}</router-link
                                        >
                                    </li>
                                    
                                    </template>
                                </ul>
                            </div>
                            <div class="mg-toc__content">                                
                                <template>
                                    <v-loader
                                        :loading="docLoading"
                                        v-if="docLoading"
                                    />
                                    <div v-else v-html="doc"></div>
                                </template>
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.mg-toc {
    display: flex; 
    flex-direction: row;
    padding-right: 30px;
    
    &__navi {
        flex: 0 0 250px;
        
    }

    &__content {
        flex: 1 1;
        padding-left: 40px;
        border-left: 1px solid $border-color;
    }

    .mg-toc__navi {
        .router-link-exact-active {
            color: #789e6b;
            font-weight: 700;
        }  
    } 

    img[src*="#small"] {
    max-width:300px;
    display: block;
    }

    img[src*="#medium"] {
        max-width:500px;
        display: block;
    }
    
    img[src*="#large"] {
        max-width:800px;
        display: block;
    }
}
</style>