import Http from "@/libraries/Http"; 

export default {
    apiVersion: 'v1', 
    RESTroute: 'system-help',

    /** 
     * Get all resources 
     * GET /resource
     */
    async GET_document(collection, doc, params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/document/${collection}/${doc}`, {params: params}); 
    }, 

    async GET_toc(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/toc`, {params: params}); 
    }, 
     
    
    
}
